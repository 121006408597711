import { useState } from "react";

export interface UsePagingReturn {
  offset: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
}

export default function usePaging(
  options: {
    page?: number;
    pageSize?: number;
  } = {},
): UsePagingReturn {
  const [page, setPage] = useState(options?.page || 0);
  const pageSize = options.pageSize || 25;

  return {
    offset: page * pageSize,
    setPage,
    page,
    pageSize,
  };
}
