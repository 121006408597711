import { AlertDialog, Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useRequestAttachmentDownload } from "api/attachments";
import { useListCustomerAgreements } from "api/customers/agreements";
import { useListCustomerAttachments } from "api/customers/attachments";
import CustomerAttachmentList from "apps/servicing/modules/customers/components/CustomerAttachmentList";
import DocumentFilter from "common/components/DocumentFilter";
import ListLayout from "common/layouts/ListLayout";
import React, {
  ChangeEventHandler,
  MouseEvent,
  ReactElement,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import getLocation from "support/get-location";

interface CustomerAttachmentsFilters {
  documentType?: string;
  toDate?: Date;
  fromDate?: Date;
  agreement?: string;
}

interface CustomerAttachmentsContainerProps {
  customerId: number;
}

export default function CustomerAttachmentsContainer({
  customerId,
}: CustomerAttachmentsContainerProps): ReactElement {
  const { t } = useTranslation("clients");
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [parameters, setParameters] = useState<CustomerAttachmentsFilters>({
    toDate: undefined,
    fromDate: undefined,
    documentType: undefined,
    agreement: undefined,
  });
  const defaultError = t("common:error.default");

  const agreements = useListCustomerAgreements(customerId);

  const agreementsFilterOptions = agreements.data
    ? agreements.data.agreements
    : [];

  const attachments = useListCustomerAttachments(customerId, {
    pageSize: rowsPerPage,
    params: {
      type: parameters.documentType,
      from: parameters.fromDate,
      to: parameters.toDate,
      agreement: parameters.agreement,
    },
  });

  const downloadAttachment = useRequestAttachmentDownload({
    onSuccess: (data) => {
      getLocation(data);
    },
    onError: () => setAlertOpen(true),
  });

  const handleDocumentDownload = async (documentId: string): Promise<void> => {
    await downloadAttachment.execute({ attachmentId: documentId });
  };

  const onPageChange = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => attachments.paging.setPage(page);

  const onRowsPerPageChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => setRowsPerPage(Number(event.target.value));

  const handleFilterChange = (
    key: string,
    value: string | Date | undefined,
  ): void => {
    setParameters({
      ...parameters,
      [key]: value,
    });
  };

  const onAgreementFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    handleFilterChange(
      event.target.name,
      event.target.value === "" ? undefined : event.target.value,
    );
  };

  const handleCloseAlert = (): void => {
    setAlertOpen(false);
  };

  const renderDocuments = () => {
    if (attachments.error || !attachments.data) {
      return (
        <Typography color="error" role="alert">
          {defaultError}
        </Typography>
      );
    }

    return (
      <CustomerAttachmentList
        data={attachments.data.results}
        loading={attachments.isValidating}
        onDocumentDownload={handleDocumentDownload}
      />
    );
  };

  return (
    <>
      <AlertDialog
        content={t("common:error.default_message")}
        title={defaultError}
        labels={{ close: t("common:alert.close") }}
        onClose={handleCloseAlert}
        open={isAlertOpen}
      />

      <ListLayout
        filters={
          <DocumentFilter
            filters={{
              type: parameters.documentType,
            }}
            labels={{
              type: t("documents.filters.type_label"),
              allTypesOption: t("documents.filters.all_types_option"),
              fromDate: t("documents.filters.from_date_label"),
              toDate: t("documents.filters.to_date_label"),
            }}
            onChange={handleFilterChange}
          >
            <TextField
              InputLabelProps={{
                htmlFor: "agreementFilter",
                shrink: true,
              }}
              inputProps={{ displayEmpty: true, id: "agreementFilter" }}
              onChange={onAgreementFilterChange}
              label={t("documents.filters.agreement_label")}
              name="agreement"
              select
              size="small"
              value={parameters.agreement ? parameters.agreement : ""}
              variant="outlined"
              margin="none"
            >
              <MenuItem value="">
                {t("documents.filters.all_agreements_option")}
              </MenuItem>
              {agreementsFilterOptions.map((agreement) => (
                <MenuItem key={agreement.id} value={String(agreement.id)}>
                  {agreement.number}
                </MenuItem>
              ))}
            </TextField>
          </DocumentFilter>
        }
        pagination={{
          onPageChange,
          onRowsPerPageChange,
          rowsPerPage: rowsPerPage,
          page: attachments.paging.page,
          count: attachments.data?.paging.total || 0,
        }}
      >
        <Loader
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
          ready={Boolean(attachments.data ?? attachments.error)}
          render={renderDocuments}
        />
      </ListLayout>
    </>
  );
}
