import { AccessControl, useSession } from "@ldms/mui-sdk/bootstrap";
import { Loader } from "@ldms/mui-sdk/templates";
import {
  Box,
  DialogContent,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useListSchedule } from "api/agreements/schedule";
import {
  B2BScheduleList,
  RepaymentScheduleList,
} from "apps/servicing/modules/agreements/components";
import ListPaymentAllocationsContainer from "apps/servicing/modules/agreements/containers/ListPaymentAllocationsContainer/ListPaymentAllocationsContainer";
import { ScheduleModel } from "generated/core/models";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <DialogContent
      role="tabpanel"
      hidden={value !== index}
      id={`repayment_schedule-tabpanel-${index}`}
      aria-labelledby={`repayment_schedule-tab-${index}`}
      sx={{ paddingY: 0, paddingX: 0 }}
      {...other}
    >
      <Box>{value === index && children}</Box>
    </DialogContent>
  );
}

const a11yProps = (index: number) => ({
  id: `repayment_schedule-tab-${index}`,
  "aria-controls": `repayment_schedule-tabpanel-${index}`,
});

interface RepaymentScheduleContainerProps {
  agreementId: number;
}

export default function RepaymentScheduleContainer({
  agreementId,
}: RepaymentScheduleContainerProps): ReactElement {
  const [value, setValue] = useState(0);
  const { t } = useTranslation(["agreements"]);
  const schedule = useListSchedule(agreementId);
  const session = useSession();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const scheduleData: ScheduleModel = schedule.data || {
    instalments: [],
    b2bInstalments: [],
    totalCapital: "",
    totalInstalmentAmount: "",
    totalInterest: "",
    totalVat: "",
  };

  return (
    <Loader
      ready={Boolean(schedule.data) || Boolean(schedule.error)}
      render={(): ReactElement => {
        if (schedule.error || !schedule.data) {
          return (
            <Typography color="error" data-testid="agreementSchedule.error">
              {t("common:error.default")}
            </Typography>
          );
        }

        return (
          <Paper
            sx={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}
          >
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={t("repayment_schedule.repayment_schedule_tab")}
                {...a11yProps(0)}
              />
              <Tab
                label={t("repayment_schedule.b2b_schedule_tab")}
                {...a11yProps(1)}
              />
              {session.hasPermission("servicing:payment-allocation:view") && (
                <Tab
                  label={t("repayment_schedule.payment_allocation_tab")}
                  {...a11yProps(2)}
                />
              )}
            </Tabs>

            <AccessControl
              allowedPermissions={["servicing:repayment-schedule:manage"]}
            >
              <TabPanel value={value} index={0}>
                <RepaymentScheduleList
                  schedule={scheduleData}
                  loading={schedule.isValidating}
                />
              </TabPanel>
            </AccessControl>
            <AccessControl
              allowedPermissions={["servicing:repayment-schedule:manage"]}
            >
              <TabPanel value={value} index={1}>
                <B2BScheduleList
                  schedule={scheduleData}
                  loading={schedule.isValidating}
                />
              </TabPanel>
            </AccessControl>
            <AccessControl
              allowedPermissions={["servicing:payment-allocation:view"]}
            >
              <TabPanel value={value} index={2}>
                <ListPaymentAllocationsContainer agreementId={agreementId} />
              </TabPanel>
            </AccessControl>
          </Paper>
        );
      }}
    />
  );
}
