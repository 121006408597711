import { Box, Container, Typography } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import App from "App";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import Shell from "shell";
import makeI18n from "support/make-i18n";

makeI18n({
  defaultNS: "common",
  ns: ["common"],
});

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

const FallbackComponent = () => {
  return (
    <Container maxWidth="sm">
      <Box
        aria-describedby="error-description"
        aria-labelledby="error-label"
        textAlign="center"
        role="alert"
        marginTop={3}
      >
        <Typography id="error-label" gutterBottom variant="h6">
          Something went wrong
        </Typography>
        <Typography id="error-description">
          Engage has experienced an unexpected error. Please refresh the page.
          If the problem persists, please contact the service desk.
        </Typography>
      </Box>
    </Container>
  );
};

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Shell>
          <App />
        </Shell>
      </ErrorBoundary>
    </StyledEngineProvider>
  </React.StrictMode>,
);
