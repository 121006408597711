import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { ControlledTextField, Loader } from "common/components";
import { BaseSyntheticEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  Control,
  DefaultValues,
  FieldError,
  Resolver,
  useForm,
  UseFormReturn,
} from "support/react-hook-form";

interface FormDialogFallbackProps {
  onClose(event?: BaseSyntheticEvent): void;
  title: string;
}

function FormDialogFallback({
  onClose,
  title,
}: FormDialogFallbackProps): ReactElement {
  const { t } = useTranslation(["reporting", "common"]);

  return (
    <>
      <DialogTitle id="portfolio-report-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("common:cancel")}
        </Button>
        <Button color="primary" disabled>
          {t("report_dialog.generate_button")}
        </Button>
      </DialogActions>
    </>
  );
}

export type BaseFormValues = { portfolio: string };

interface PortfolioReportFormProps<
  TPortfolioFormValues extends BaseFormValues,
> {
  children?: (form: UseFormReturn<TPortfolioFormValues>) => ReactElement;
  defaultValues?: DefaultValues<TPortfolioFormValues>;
  error: string | undefined;
  onClose: () => void;
  onSubmit: (formValues: TPortfolioFormValues) => void;
  portfolios: { label: string; value: string }[];
  resolver: Resolver<TPortfolioFormValues>;
  title: string;
}

function PortfolioReportForm<TPortfolioFormValues extends BaseFormValues>({
  children,
  defaultValues,
  error,
  onClose,
  onSubmit,
  portfolios,
  resolver,
  title,
}: PortfolioReportFormProps<TPortfolioFormValues>): ReactElement {
  const { t } = useTranslation("reporting");
  const form = useForm<TPortfolioFormValues>({
    defaultValues,
    resolver,
  });

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      aria-labelledby="portfolio-report-dialog-title"
    >
      <DialogTitle id="portfolio-report-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{title}</Box>
          <Box>
            <IconButton
              aria-label={t("common:close")}
              onClick={onClose}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ControlledTextField
          helperText={(form.formState.errors?.portfolio as FieldError)?.message}
          SelectProps={{ displayEmpty: true }}
          error={(form.formState.errors?.portfolio as FieldError)?.message}
          control={form.control as Control}
          id="portfolio"
          name="portfolio"
          label={t("report_dialog.portfolio_label")}
          select
          disabled={form.formState.isSubmitting}
        >
          <MenuItem key="" value="">
            {t("common:please_select")}
          </MenuItem>
          {portfolios.map((portfolio) => (
            <MenuItem key={portfolio.value} value={portfolio.value}>
              {portfolio.label}
            </MenuItem>
          ))}
        </ControlledTextField>

        {children?.(form)}

        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          type="submit"
          disabled={form.formState.isSubmitting}
          variant="contained"
          color="primary"
        >
          {t("report_dialog.generate_button")}
        </Button>
      </DialogActions>
    </form>
  );
}

interface PortfolioReportFormDialogProps<
  TPortfolioFormValues extends BaseFormValues,
> extends Omit<PortfolioReportFormProps<TPortfolioFormValues>, "portfolios"> {
  open: boolean;
  portfolios: { label: string; value: string }[];
  ready: boolean;
}

export default function PortfolioReportFormDialog<
  TPortfolioFormValues extends BaseFormValues = BaseFormValues,
>({
  children,
  defaultValues = { portfolio: "" } as DefaultValues<TPortfolioFormValues>,
  error,
  onClose,
  onSubmit,
  open,
  portfolios,
  ready,
  resolver,
  title,
}: PortfolioReportFormDialogProps<TPortfolioFormValues>): ReactElement {
  return (
    <Dialog
      aria-labelledby="portfolio-report-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <Loader
        fallback={<FormDialogFallback onClose={onClose} title={title} />}
        ready={ready}
        render={(): ReactElement => (
          <PortfolioReportForm
            portfolios={portfolios}
            children={children}
            defaultValues={defaultValues}
            error={error}
            onClose={onClose}
            onSubmit={onSubmit}
            resolver={resolver}
            title={title}
          />
        )}
      />
    </Dialog>
  );
}
