import {
  AlertDialog,
  ConfirmationDialog,
  Loader,
} from "@ldms/mui-sdk/templates";
import { Box, CircularProgress, Typography } from "@mui/material";
import { BankAccountsList } from "apps/servicing/modules/customers/components";
import UpdateBankAccount from "apps/servicing/modules/customers/containers/UpdateBankAccount";
import { useConfirm, useResponseError } from "common/hooks";
import ListLayout from "common/layouts/ListLayout";
import { useApi } from "common/providers";
import { ClientCommandApi, ClientQueryApi } from "generated/core/apis";
import { BankAccountDetailsModel } from "generated/core/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import errorHandler, { ErrorLike } from "support/error-handler";
import useStickySWR from "support/use-sticky-swr";
import useSWR from "swr";

interface BankAccountsProps {
  clientId: number;
}

export default function BankAccounts({
  clientId,
}: BankAccountsProps): ReactElement {
  const { t } = useTranslation("customers");
  const error = useResponseError();
  const confirm = useConfirm();
  const clientQueryApi = useApi(ClientQueryApi);
  const clientCommandApi = useApi(ClientCommandApi);
  const bankAccounts = useStickySWR(
    ["/clients/:id/bank-accounts", clientId],
    () => clientQueryApi.listBankAccounts({ clientId }),
  );
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<
    number | undefined
  >();
  const bankAccountDetails = useSWR<BankAccountDetailsModel, ErrorLike>(
    selectedBankAccountId
      ? [
          "/agreement/:clientId/bank-accounts/:bankAccountId",
          clientId,
          selectedBankAccountId,
        ]
      : null,
    (_, id: number, bankId) =>
      clientQueryApi.getBankAccountDetails({
        clientId: id,
        bankAccountId: bankId,
      }),
  );
  const [isUpdateBankAccountDialogOpen, setUpdateBankAccountDialogOpen] =
    useState(false);

  const handleUpdateBankAccountDialogClose = (): void => {
    setUpdateBankAccountDialogOpen(false);
    setSelectedBankAccountId(undefined);
  };

  const onUpdateBankAccountSubmit = (): void => {
    bankAccounts.mutate();
    setUpdateBankAccountDialogOpen(false);
    setSelectedBankAccountId(undefined);
  };

  const onCloseErrorDialog = (): void => error.reset();

  const onDeleteBankAccount = (bankAccountId: number): void => {
    confirm.handlePrompt(async (): Promise<void> => {
      try {
        await clientCommandApi.deleteBankAccount({
          clientId,
          bankAccountId,
        });
        bankAccounts.mutate();
      } catch (errorResponse) {
        error.setError((await errorHandler(errorResponse)).code);
      }
    });
  };

  const onUpdateBankAccount = (bankAccountId: number): void => {
    setSelectedBankAccountId(bankAccountId);
    setUpdateBankAccountDialogOpen(true);
  };

  return (
    <>
      <ConfirmationDialog
        content={
          <>
            <Typography>
              {t("remove_bank_account.confirmation_message")}
            </Typography>
            <Typography>
              {t("remove_bank_account.confirmation_message_mandates")}
            </Typography>
          </>
        }
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("remove_bank_account.confirmation_title_label")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
        title={t("remove_bank_account.error_title")}
      />

      {bankAccountDetails.data && selectedBankAccountId && (
        <UpdateBankAccount
          clientId={bankAccountDetails.data.clientId}
          bankAccountId={selectedBankAccountId}
          onSubmit={onUpdateBankAccountSubmit}
          open={isUpdateBankAccountDialogOpen}
          onClose={handleUpdateBankAccountDialogClose}
          defaultValues={bankAccountDetails.data}
        />
      )}

      <ListLayout>
        <Loader
          ready={Boolean(bankAccounts.data ?? bankAccounts.error)}
          render={() => {
            if (bankAccounts.error || !bankAccounts.data) {
              return (
                <Typography
                  color="error"
                  aria-label={t("common:error.default")}
                  role="alert"
                >
                  {t("common:error.default")}
                </Typography>
              );
            }

            return (
              <BankAccountsList
                data={bankAccounts.data}
                loading={bankAccounts.isValidating}
                onDeleteBankAccount={onDeleteBankAccount}
                onUpdateBankAccount={onUpdateBankAccount}
              />
            );
          }}
          fallback={
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress />
            </Box>
          }
        />
      </ListLayout>
    </>
  );
}
