import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { DescriptionList } from "@ldms/mui-sdk/components";
import {
  AlertDialog,
  ConfirmationDialog,
  Loader,
} from "@ldms/mui-sdk/templates";
import { Button, Stack } from "@mui/material";
import { useGetBankAccountDetails } from "api/customers/bank-accounts/getBankAccountDetails";
import { MandatesList } from "apps/servicing/modules/customers/components";
import { QueryError } from "common/components";
import Surface from "common/components/Surface";
import { useConfirm, useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { ClientCommandApi } from "generated/core/apis";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";

interface BankAccountDetailsProps {
  bankAccountId: number;
  clientId: number;
  onCancelMandates(): void;
}

export default function BankAccountDetails({
  bankAccountId,
  clientId,
  onCancelMandates: onCancelMandatesCallBack,
}: BankAccountDetailsProps) {
  const { t } = useTranslation(["clients", "common"]);
  const bankAccount = useGetBankAccountDetails(clientId, bankAccountId);
  const commandApi = useApi(ClientCommandApi);
  const confirm = useConfirm();
  const error = useResponseError();

  const onCloseErrorDialog = (): void => error.reset();

  const onCancelMandates = async (): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      try {
        await commandApi.cancelMandates({
          clientId,
          bankAccountId,
        });
        onCancelMandatesCallBack();
      } catch (errorResponse) {
        error.setError((await errorHandler(errorResponse)).code);
      }
    });
  };

  const renderBankAccountDetails = () => {
    if (bankAccount.error || !bankAccount.data) {
      return <QueryError onRetry={bankAccount.refetch} />;
    }

    return (
      <Stack spacing={2}>
        <Surface title={t("bank_account_details.banks_account_details_label")}>
          <DescriptionList
            label={t("bank_account_details.banks_account_details_label")}
          >
            <DescriptionList.Item label={t("bank_account_details.type_label")}>
              {bankAccount.data.contactType}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.bank_name_label")}
            >
              {bankAccount.data.bankName}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.address_label")}
            >
              {[
                bankAccount.data.address?.addressLine1,
                bankAccount.data.address?.addressLine2,
                bankAccount.data.address?.addressLine3,
                bankAccount.data.address?.addressLine4,
                bankAccount.data.address?.postcode,
              ]
                .filter(Boolean)
                .join(" ")}
            </DescriptionList.Item>
            <DescriptionList.Item label={t("bank_account_details.iban_label")}>
              {bankAccount.data.iban}
            </DescriptionList.Item>
            <DescriptionList.Item label={t("bank_account_details.bic_label")}>
              {bankAccount.data.bic}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.account_holders_name_label")}
            >
              {bankAccount.data.accountName}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.account_number_label")}
            >
              {bankAccount.data.accountNumber}
            </DescriptionList.Item>
            <DescriptionList.Item
              label={t("bank_account_details.sort_code_label")}
            >
              {bankAccount.data.sortCode}
            </DescriptionList.Item>
          </DescriptionList>
        </Surface>

        <Surface
          title={t("mandates.mandates_title")}
          action={
            <AccessControl
              allowedPermissions={["servicing:bank-accounts:manage"]}
            >
              <Button
                disabled={bankAccount.data.mandates.length === 0}
                onClick={onCancelMandates}
              >
                {t("mandates.cancel_mandates_button")}
              </Button>
            </AccessControl>
          }
          disableGutters
        >
          <MandatesList
            data={bankAccount.data.mandates}
            loading={bankAccount.isValidating}
          />
        </Surface>
      </Stack>
    );
  };

  return (
    <>
      <Loader
        ready={Boolean(bankAccount.data ?? bankAccount.error)}
        render={renderBankAccountDetails}
      />

      <ConfirmationDialog
        content={t("mandates.cancel_confirmation.message")}
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("mandates.cancel_confirmation.title_label")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
        title={t("mandates.error_title")}
      />
    </>
  );
}
