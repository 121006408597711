import { PagedQuery, useQueryPaged } from "common/hooks";
import { useApi } from "common/providers";
import { PortfolioBankAccountQueryApi } from "generated/core/apis";
import { PortfolioBankAccountListItemModel } from "generated/core/models";
import { PortfolioBankAccountsApi } from "generated/servicing-v2/apis";
import { PortfolioBankAccountListItemModel as PortfolioBankAccountListItemModelV2 } from "generated/servicing-v2/models";
import { keys } from ".";

export const useListPortfolioBankAccounts: PagedQuery<
  PortfolioBankAccountListItemModel
> = ({ pageSize } = {}) => {
  const api = useApi(PortfolioBankAccountQueryApi);

  return useQueryPaged(
    keys.list(),
    async (_key, paging) =>
      api.listPortfolioBankAccountsRaw({
        limit: paging.pageSize,
        offset: paging.offset,
      }),
    { limit: pageSize },
  );
};

export const useListPortfolioBankAccountsV2: PagedQuery<
  PortfolioBankAccountListItemModelV2
> = ({ pageSize } = {}) => {
  const api = useApi(PortfolioBankAccountsApi, "v2");

  return useQueryPaged(
    keys.list(),
    async (_key, paging) =>
      api.listPortfolioBankAccountsRaw({
        limit: paging.pageSize,
        offset: paging.offset,
      }),
    { limit: pageSize },
  );
};
