import { list } from "api/documents/letters/email/keys";
import { PagedQuery, useQueryPaged } from "common/hooks";
import { useApi } from "common/providers";
import { LetterApi } from "generated/documents/apis";
import {
  DeliveryStatusModel,
  LetterListItemModel,
} from "generated/documents/models";

export const useListLettersToEmail: PagedQuery<
  LetterListItemModel,
  {
    status?: DeliveryStatusModel;
    from?: Date;
    to?: Date;
    type?: string;
  }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(LetterApi);

  return useQueryPaged(
    list({
      status: params.status,
      from: params.from,
      to: params.to,
      type: params.type,
    }),
    async (_key, paging) =>
      api.listLettersToEmailRaw({
        limit: paging.pageSize,
        offset: paging.offset,
        status: params.status,
        from: params.from,
        to: params.to,
        type: params.type,
      }),
    { limit: pageSize },
  );
};
