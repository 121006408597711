import { AlertDialog, ConfirmationDialog } from "@ldms/mui-sdk/templates";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import BankAccountFieldSet from "apps/servicing/modules/customers/components/BankAccountFieldSet";
import { useBankAccountResolver } from "apps/servicing/modules/customers/hooks";
import { BankAccountFormValues } from "apps/servicing/modules/customers/hooks/useBankAccountResolver";
import { useConfirm, useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { ClientCommandApi } from "generated/core/apis";
import {
  BankAccountDetailsModel,
  UpdateBankAccountModel,
} from "generated/core/models";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";

interface AddBankAccountProps {
  clientId: number;
  bankAccountId: number;
  onSubmit(): void;
  onClose(): void;
  open: boolean;
  defaultValues: BankAccountDetailsModel;
}

export default function UpdateBankAccount({
  clientId,
  bankAccountId,
  onSubmit: onSubmitCallback,
  onClose,
  open,
  defaultValues,
}: AddBankAccountProps): ReactElement {
  const { t } = useTranslation(["clients", "common"]);
  const clientCommandApi = useApi(ClientCommandApi);
  const error = useResponseError();
  const onCloseErrorDialog = (): void => error.reset();
  const confirm = useConfirm();
  const resolver = useBankAccountResolver();
  const { reset, ...form } = useForm<BankAccountFormValues>({
    defaultValues: {
      ...defaultValues,
      typeId: String(clientId),
      addressLine1: defaultValues.address?.addressLine1,
      addressLine2: defaultValues.address?.addressLine2,
      addressLine3: defaultValues.address?.addressLine3,
      addressLine4: defaultValues.address?.addressLine4,
      postcode: defaultValues.address?.postcode,
      sortCode: defaultValues.sortCode,
      accountHolderName: defaultValues.accountName,
    },
    resolver,
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const onSubmit = async (data: BankAccountFormValues): Promise<void> => {
    confirm.handlePrompt(async (): Promise<void> => {
      try {
        const updateBankAccountModel: UpdateBankAccountModel = {
          bankName: data.bankName,
          accountHolderName: data.accountHolderName,
          accountNumber: data.accountNumber,
          sortCode: data.sortCode,
          iban: data.iban === "" ? undefined : data.iban,
          bic: data.bic === "" ? undefined : data.bic,
          address: {
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            addressLine3: data.addressLine3,
            addressLine4: data.addressLine4,
            postcode: data.postcode,
          },
          migrateMandates: data.migrateMandates,
        };
        await clientCommandApi.updateBankAccount({
          clientId: clientId,
          bankAccountId,
          updateBankAccountModel,
        });
        onSubmitCallback();
      } catch (errorResponse) {
        error.setError((await errorHandler(errorResponse)).code);
      }
    });
  };

  const titleLabel = t("edit_bank_account.title_label");
  const migrateMandates = form.watch("migrateMandates");

  return (
    <>
      <Dialog
        aria-labelledby="edit-bank-account-title"
        maxWidth="sm"
        open={open}
        onClose={onClose}
        fullWidth
      >
        <form
          method="POST"
          aria-label={titleLabel}
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <DialogTitle id="edit-bank-account-title">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>{titleLabel}</Box>
              <Box>
                <IconButton
                  aria-label={t("common:close")}
                  onClick={onClose}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent>
            <BankAccountFieldSet
              errors={form.formState.errors}
              register={form.register}
            />

            <FormControlLabel
              {...form.register("migrateMandates")}
              name="migrateMandates"
              label={String(t("edit_bank_account.migrate_mandates_label"))}
              aria-label={t("edit_bank_account.migrate_mandates_label")}
              labelPlacement="end"
              control={<Checkbox color="primary" />}
            />

            {error.message && (
              <Typography color="error" data-testid="form.error">
                {error.message}
              </Typography>
            )}
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={onClose}>
              {t("common:cancel")}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {t("edit_bank_account.edit_account_button")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationDialog
        content={
          <Typography>
            {migrateMandates
              ? t("edit_bank_account.confirmation_message_migrating_mandates")
              : t(
                  "edit_bank_account.confirmation_message_no_mandates_migrated",
                )}
          </Typography>
        }
        open={confirm.isOpen}
        onConfirm={confirm.handleConfirm}
        onReject={confirm.handleReject}
        title={t("edit_bank_account.confirmation_title")}
        labels={{
          confirm: t("common:yes"),
          reject: t("common:no"),
        }}
      />

      <AlertDialog
        title={t("edit_bank_account.error_title")}
        content={error.message}
        labels={{ close: t("common:alert.close") }}
        onClose={onCloseErrorDialog}
        open={Boolean(error.message)}
      />
    </>
  );
}
