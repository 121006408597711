import * as customerKeys from "api/customers/keys";

export const all = (customerId: number) => [
  ...customerKeys.detail(customerId),
  "bank-accounts",
];

export const detail = (customerId: number, bankAccountId: number) => [
  ...all(customerId),
  "bank-accounts",
  "detail",
  bankAccountId,
];
