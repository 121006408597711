const customerDisplayName = (
  id: number,
  companyName: string | undefined,
  contact:
    | {
        title?: string;
        firstName?: string;
        secondName?: string;
        lastName?: string;
      }
    | undefined,
): string =>
  companyName ||
  [contact?.title, contact?.firstName, contact?.secondName, contact?.lastName]
    .filter(Boolean)
    .join(" ") ||
  String(id);

export default customerDisplayName;
