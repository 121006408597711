import { AddButton } from "@ldms/mui-sdk/components";
import { useCreateUser, useListRoles } from "api/users";
import CreateUserFormDialog from "apps/admin/components/CreateUserFormDialog";
import { Role } from "apps/admin/types";
import { useResponseError } from "common/hooks";
import { CreateUserModel } from "generated/admin/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CreateUserContainer(): ReactElement {
  const { t } = useTranslation("users");
  const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false);

  const closeCreateUserDialogSuccess = (): void => {
    setIsCreateUserDialogOpen(false);
  };

  const createUser = useCreateUser({
    onSuccess: closeCreateUserDialogSuccess,
    onError: (response) => error.setError(response.code),
  });

  const openUpdateUserDialog = (): void => {
    setIsCreateUserDialogOpen(true);
  };

  const closeCreateUserDialog = (): void => {
    createUser.reset();
    setIsCreateUserDialogOpen(false);
  };

  const error = useResponseError([
    ["invalid_password_format", t("error.invalid_password_format")],
    ["user_exists", t("error.user_exists")],
  ]);

  const roles = useListRoles();

  const roleOptions =
    roles.data?.map((item) => ({
      label: item.name,
      value: String(item.id),
    })) ?? [];

  const matchRoles = (
    selectedRoles: string[],
    availableRoles: Role[],
  ): string[] =>
    selectedRoles
      .map((id) => availableRoles.find((role) => String(role.id) === id))
      .filter((role: Role | undefined): role is Role => !!role)
      .map((role) => role?.name);

  const makeOnCreateUserSubmit = async (
    data: CreateUserModel,
  ): Promise<void> => {
    await createUser.execute({
      ...data,
      roles: matchRoles(data.roles, roles.data ?? []),
    });
  };

  return (
    <>
      <AddButton variant="contained" onClick={openUpdateUserDialog} />

      <CreateUserFormDialog
        onSubmit={makeOnCreateUserSubmit}
        open={isCreateUserDialogOpen}
        onClose={closeCreateUserDialog}
        error={error.message}
        roleOptions={roleOptions}
      />
    </>
  );
}
