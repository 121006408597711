import * as keys from "api/assets/valuations/keys";
import { PagedQuery, useQueryPaged } from "common/hooks";
import { useApi } from "common/providers";
import { AssetQueryApi } from "generated/core/apis";
import { AssetValuationListItemModel } from "generated/core/models";

export const useListValuations: PagedQuery<
  AssetValuationListItemModel,
  { assetId: number }
> = ({ pageSize, params = {} } = {}) => {
  const api = useApi(AssetQueryApi);

  return useQueryPaged(
    keys.list(Number(params.assetId)),
    async (_key, paging) => {
      return api.listValuationsRaw({
        assetId: Number(params.assetId),
        limit: paging.pageSize,
        offset: paging.offset,
      });
    },
    { limit: pageSize },
  );
};
