import { useYupResolver } from "common/hooks";
import { Resolver } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface DefaultBankAccountFormValues {
  typeId?: string;
  bankName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  postcode: string;
  iban?: string;
  bic?: string;
  accountHolderName: string;
  accountNumber: string;
  sortCode: string;
  migrateMandates?: boolean;
}

interface AddBankAccountFormValues extends DefaultBankAccountFormValues {
  typeId: string;
}

interface UpdateBankAccountFormValues extends DefaultBankAccountFormValues {
  migrateMandates: boolean;
}

export type BankAccountFormValues =
  | AddBankAccountFormValues
  | UpdateBankAccountFormValues;

export default function useBankAccountResolver(): Resolver<BankAccountFormValues> {
  const { t } = useTranslation("clients");
  const validateDependentField = (
    value: string | undefined,
    length: number,
  ): boolean => {
    if (length) {
      return Boolean(value);
    }
    return true;
  };
  const labels = {
    accountNumber: "manage_bank_account.account_number_label",
    sortCode: "manage_bank_account.sort_code_label",
    iban: "manage_bank_account.iban_label",
    bic: "manage_bank_account.bic_label",
    isRequiredValidation: "common:validation.is_required",
  };

  return useYupResolver<BankAccountFormValues>((yup) =>
    yup.object().shape({
      accountHolderName: yup
        .string()
        .isRequired(t("manage_bank_account.account_holders_name_label"))
        .maxCharacters(50, t("manage_bank_account.account_holders_name_label")),
      bankName: yup
        .string()
        .maxCharacters(30, t("manage_bank_account.bank_name_label")),
      addressLine1: yup
        .string()
        .maxCharacters(50, t("manage_bank_account.address_line_1_label")),
      addressLine2: yup
        .string()
        .maxCharacters(50, t("manage_bank_account.address_line_2_label")),
      addressLine3: yup
        .string()
        .maxCharacters(50, t("manage_bank_account.address_line_3_label")),
      addressLine4: yup
        .string()
        .maxCharacters(50, t("manage_bank_account.address_line_4_label")),
      postcode: yup
        .string()
        .maxCharacters(10, t("manage_bank_account.postcode_label")),
      typeId: yup.string().when("migrateMandates", {
        is: undefined,
        then: yup.string().isRequired(t("add_bank_account.type_label")),
        otherwise: yup.string().notRequired(),
      }),
      accountNumber: yup
        .string()
        .test({
          name: "isDependentOnSortCode",
          message: t(labels.isRequiredValidation, {
            label: t(labels.accountNumber),
          }),
          test: (value, testContext) =>
            validateDependentField(value, testContext.parent.sortCode),
        })
        .oneOfBankDetailsRequired("accountNumberRequired")
        .maxCharacters(20, t(labels.accountNumber))
        .isNumbers(t(labels.accountNumber)),
      sortCode: yup
        .string()
        .test({
          name: "isDependentOnAccountNumber",
          message: t(labels.isRequiredValidation, {
            label: t(labels.sortCode),
          }),
          test: (value, testContext) =>
            validateDependentField(value, testContext.parent.accountNumber),
        })
        .oneOfBankDetailsRequired("sortCodeRequired")
        .isNumbers(t(labels.sortCode))
        .maxCharacters(10, t(labels.sortCode)),
      bic: yup
        .string()
        .test({
          name: "isDependentOnIban",
          message: t(labels.isRequiredValidation, {
            label: t(labels.bic),
          }),
          test: (value, testContext) =>
            validateDependentField(value, testContext.parent.iban),
        })
        .oneOfBankDetailsRequired("bicRequired")
        .maxCharacters(30, t(labels.bic))
        .validateBic(),
      iban: yup
        .string()
        .test({
          name: "isDependentOnBic",
          message: t(labels.isRequiredValidation, {
            label: t(labels.iban),
          }),
          test: (value, testContext) =>
            validateDependentField(value, testContext.parent.bic),
        })
        .oneOfBankDetailsRequired("ibanRequired")
        .maxCharacters(30, t(labels.iban))
        .validateIban(),
    }),
  );
}
