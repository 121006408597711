import { usePaging, UsePagingReturn } from "common/hooks";
import { ApiResponse } from "generated";
import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import useSWR, { Key } from "swr";

export type PagedQuery<TData, TParams = {}> = (query?: {
  pageSize?: number;
  params?: TParams;
}) => UseQueryPagedReturn<TData>;

interface PagedResults<TData> {
  paging: {
    page: number;
    pages: number;
    total: number;
  };
  results: TData[];
}

export interface UseQueryPagedReturn<TData, TError = Error> {
  data?: PagedResults<TData>;
  error?: TError;
  isValidating: boolean;
  paging: UsePagingReturn;
  refetch: () => Promise<void>;
}

export default function useQueryPaged<TData, TError = Error>(
  key: Key,
  fetcher: (key: Key, paging: UsePagingReturn) => Promise<ApiResponse<TData[]>>,
  options?: {
    limit?: number;
  },
): UseQueryPagedReturn<TData, TError> {
  const previousKey = useRef(key);
  const paging = usePaging({ pageSize: options?.limit });
  const { setPage } = paging;

  useEffect(() => {
    if (!isEqual(previousKey.current, key)) {
      previousKey.current = key;
      setPage(0);
    }
  }, [key, setPage]);

  const wrapApiResponse = async (
    apiResponse: ApiResponse<TData[]>,
  ): Promise<PagedResults<TData>> => {
    const results = await apiResponse.value();

    const limit = Number(apiResponse.raw.headers.get("x-limit"));
    const offset = Number(apiResponse.raw.headers.get("x-offset"));
    const total = Number(apiResponse.raw.headers.get("x-total-size"));

    const pages = Math.ceil(total / limit);

    return {
      results,
      paging: {
        page: Math.floor(offset / limit),
        pages,
        total,
      },
    };
  };

  const response = useSWR(
    [
      ...(Array.isArray(key) ? key : [key]),
      paging.pageSize,
      paging.page * paging.pageSize,
    ],
    async (...args: unknown[]) => {
      const apiResponse = await fetcher(args, paging);
      return wrapApiResponse(apiResponse);
    },
  );

  return {
    paging,
    data: response.data,
    error: response.error,
    isValidating: response.isValidating,
    refetch: async (): Promise<void> => {
      await response.mutate();
    },
  };
}
