import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import {
  Button,
  Grid,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { LoadableTableHead, NoResults } from "common/components";
import { isEmpty } from "lodash";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export interface BankAccount {
  id: number;
  contactType?: string;
  contactName?: string;
  bankName?: string;
  accountName?: string;
  accountNumber?: string;
  directDebitMandate?: boolean;
}

interface BankAccountsListProps {
  data?: BankAccount[];
  loading: boolean;
  onDeleteBankAccount(bankAccountId: number): void;
  onUpdateBankAccount(bankAccountId: number): void;
}

export default function BankAccountsList({
  data,
  loading,
  onDeleteBankAccount,
  onUpdateBankAccount,
}: BankAccountsListProps): ReactElement {
  const { t } = useTranslation("clients");

  const tableHeaders: string[] = [
    t("bank_accounts.list.contact_label"),
    t("bank_accounts.list.bank_name_label"),
    t("bank_accounts.list.account_holders_name_label"),
    t("bank_accounts.list.mandate_held_label"),
    "",
  ];

  const makeDeleteBankAccountHandler = (id: number) => (): void =>
    onDeleteBankAccount(id);

  const makeUpdateBankAccountHandler = (id: number) => (): void => {
    onUpdateBankAccount(id);
  };

  return (
    <>
      <Table
        aria-label={t("bank_accounts.list.label")}
        size="small"
        stickyHeader
      >
        <LoadableTableHead headings={tableHeaders} loading={loading} />
        <TableBody>
          {data?.map((item) => (
            <TableRow key={item.id} hover>
              <TableCell padding="none">
                <ListItemButton
                  component={RouterLink}
                  dense
                  to={`./${String(item.id)}`}
                >
                  <ListItemText
                    primary={
                      isEmpty(item.contactName)
                        ? `${item.contactType}`
                        : `${item.contactType} - ${item.contactName}`
                    }
                    primaryTypographyProps={{ color: "primary" }}
                  />
                </ListItemButton>
              </TableCell>
              <TableCell>{item.bankName}</TableCell>
              <TableCell>{item.accountName}</TableCell>
              <TableCell>
                {item.directDebitMandate
                  ? t("bank_accounts.mandate_held_label")
                  : t("bank_accounts.no_mandate_held_label")}
              </TableCell>
              <TableCell sx={{ paddingY: 0.25 }}>
                <AccessControl
                  allowedPermissions={["servicing:bank-accounts:manage"]}
                >
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={makeUpdateBankAccountHandler(item.id)}
                        size="small"
                      >
                        {t("bank_accounts.edit_account_button")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={makeDeleteBankAccountHandler(item.id)}
                        size="small"
                      >
                        {t("bank_accounts.remove_account_button")}
                      </Button>
                    </Grid>
                  </Grid>
                </AccessControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {(data || []).length === 0 && <NoResults />}
    </>
  );
}
