import { PagedQuery, useQueryPaged } from "common/hooks";
import { useApi } from "common/providers";
import { BatchQueryApi } from "generated/documents/apis";
import { BatchMetadataModel } from "generated/documents/models";
import { keys } from ".";

export const useGetBatch: PagedQuery<
  BatchMetadataModel,
  { batchId: string }
> = ({ pageSize, params = { batchId: "" } } = {}) => {
  const api = useApi(BatchQueryApi);

  return useQueryPaged(
    keys.detail(params.batchId),
    async (_key, paging) =>
      api.getBatchRaw({
        batchId: params.batchId,
        limit: paging.pageSize,
        offset: paging.offset,
      }),
    { limit: pageSize },
  );
};
