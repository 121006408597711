import {
  Box,
  Breadcrumbs,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEnvironment } from "common/providers/EnvironmentProvider";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import useResizeObserver from "use-resize-observer";

type Breadcrumb = { href?: string; label: string };

export interface ModuleHeaderProps {
  breadcrumbs?: Breadcrumb[];
  labelledChips?: ReactNode;
  onResize?: (rect: DOMRectReadOnly) => void;
  title?: ReactNode;
  action?: ReactNode;
}

export default function ModuleHeader({
  breadcrumbs,
  labelledChips,
  onResize,
  title,
  action,
}: ModuleHeaderProps) {
  const { t } = useTranslation();
  const targetRef = useRef<HTMLDivElement>(null);
  const [isFirstEffect, setFirstEffect] = useState(true);
  const environment = useEnvironment();

  useResizeObserver<HTMLDivElement>({
    ref: targetRef,
    onResize: () => {
      if (targetRef.current) {
        onResize?.(targetRef.current.getBoundingClientRect());
      }
    },
  });

  useLayoutEffect(() => {
    const target = targetRef.current;
    if (target && isFirstEffect) {
      onResize?.(target.getBoundingClientRect());
    }
    setFirstEffect(false);
  }, [targetRef, onResize, isFirstEffect]);

  return (
    <Box
      component="header"
      position="sticky"
      width="100%"
      top={`${48 + (!environment.isProduction ? 30 : 0)}px`}
      zIndex={2}
      ref={targetRef}
    >
      <Box
        alignItems="flex-end"
        bgcolor="background.paper"
        display="flex"
        minHeight={52}
        paddingX={3}
        paddingY={1.25}
      >
        <Box flexShrink={0} flexGrow={1} marginBottom={0.5}>
          <Breadcrumbs aria-label={t("breadcrumbs.label")}>
            {breadcrumbs?.map((crumb) =>
              typeof crumb.href !== "undefined" ? (
                <Link
                  key={crumb.label}
                  component={RouterLink}
                  to={crumb.href}
                  variant="h5"
                >
                  {crumb.label}
                </Link>
              ) : (
                <Typography key={crumb.label}>{crumb.label}</Typography>
              ),
            )}
            <Typography
              color="textPrimary"
              component="div"
              variant="h5"
              role="heading"
            >
              {title}
            </Typography>
          </Breadcrumbs>
        </Box>
        {labelledChips && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              minHeight: "32px",
              justifyContent: "flex-end",
              marginLeft: 3,
              rowGap: 1,
              columnGap: 2,
            }}
          >
            {labelledChips}
          </Box>
        )}
        {action && (
          <Box display="flex" marginY={-0.5} role="toolbar">
            <Stack direction="row" spacing={1}>
              {action}
            </Stack>
          </Box>
        )}
      </Box>
      <Divider light />
    </Box>
  );
}
