import { useFormat } from "@ldms/mui-sdk/formatting";
import { Box, CircularProgress } from "@mui/material";
import { useGetCustomer } from "api/customers/contacts/getCustomer";
import AppError from "common/components/AppError";
import LabelledChip from "common/components/LabelledChip";
import ModuleHeader from "common/components/ModuleHeader";
import SidebarNavigation from "common/containers/SidebarNavigation";
import SidebarLayout from "common/layouts/SidebarLayout";
import { CustomerOverviewModel } from "generated/core/models";
import React, {
  ReactElement,
  createContext,
  useContext,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { withTranslationLoader } from "sdk/views";

interface CustomerLayoutProps {
  customerId: number;
}

export interface CustomerContextValue {
  data: CustomerOverviewModel;
  isValidating: boolean;
  refetch: () => Promise<void>;
}

export interface UseCustomerReturn {
  data: CustomerOverviewModel;
  isValidating: boolean;
  refetch: () => Promise<void>;
}

export const CustomerContext = createContext<CustomerContextValue>(
  {} as CustomerContextValue,
);

export const useCustomer = (): UseCustomerReturn => {
  const customer = useContext(CustomerContext);

  if (
    !Object.values(customer).length &&
    process.env.NODE_ENV !== "production"
  ) {
    throw new Error(
      "useCustomer must be wrapped in a CustomerContext.Provider",
    );
  }

  return customer;
};

const CustomerProvider: React.FC<
  CustomerLayoutProps & { children: React.ReactElement }
> = ({ customerId, children }) => {
  const customer = useGetCustomer(customerId);

  if (!customer.data) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CustomerContext.Provider
      value={{
        data: customer.data,
        isValidating: customer.isValidating,
        refetch: customer.refetch,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

function CustomerLayout({ customerId }: CustomerLayoutProps): ReactElement {
  const [headerRect, setHeaderRect] = useState<DOMRectReadOnly>();
  const { t } = useTranslation("customers");
  const customer = useGetCustomer(customerId);
  const { formatAmount } = useFormat();

  // This function is a callback which is triggered by a ResizeObserver which
  // means it can only be tested in a real browser environment
  const onHeaderResize = (rect: DOMRectReadOnly) => {
    setHeaderRect(rect);
  };

  const name = customer.data?.isIndividual
    ? customer.data?.name
    : customer.data?.companyName;

  const displayName =
    customer.data?.isIndividual && customer.data?.companyName
      ? `${name} (${customer.data?.companyName})`
      : name;

  const navigationMenu = (
    [
      [t("customer_layout.navigation_links.overview"), "./overview"],
      [t("customer_layout.navigation_links.activity"), "./activity"],
      [t("customer_layout.navigation_links.assets"), "./assets"],
      [t("customer_layout.navigation_links.agreements"), "./agreements"],
      [t("customer_layout.navigation_links.memos"), "./memos"],
      [t("customer_layout.navigation_links.contacts"), "./contacts"],
      [t("customer_layout.navigation_links.bank_accounts"), "./bank-accounts"],
      [t("customer_layout.navigation_links.documents"), "./documents"],
    ] as [string, string][]
  ).map(([label, to, display = true]) => ({
    label,
    to,
    display,
  }));

  const errorCodesTranslations = new Map([
    [
      "404",
      {
        message: t(
          "customer_layout.errors.customer_not_found.description_text",
        ),
        title: t("customer_layout.errors.customer_not_found.message_text"),
      },
    ],
    [
      "400",
      {
        message: t(
          "customer_layout.errors.customer_not_found.description_text",
        ),
        title: t("customer_layout.errors.customer_not_found.message_text"),
      },
    ],
  ]);

  const errorCodeTranslation = errorCodesTranslations?.get(
    String(customer.error?.status),
  );

  if (customer.error) {
    return (
      <AppError
        message={errorCodeTranslation?.message}
        title={errorCodeTranslation?.title}
      />
    );
  }

  return (
    <Box height="100%" display="grid" gridTemplateRows="max-content 1fr">
      <ModuleHeader
        title={displayName}
        labelledChips={
          customer.data ? (
            <>
              <LabelledChip
                label="Current Balance"
                value={formatAmount(customer.data?.totalCurrentBalance)}
              />
              <LabelledChip
                label={t("customer_layout.summary_labels.address")}
                value={[
                  customer.data.address?.addressLine1,
                  customer.data.address?.addressLine2,
                  customer.data.address?.addressLine3,
                  customer.data.address?.addressLine4,
                  customer.data.address?.postcode,
                ]
                  .filter(Boolean)
                  .join(", ")}
              />
              <LabelledChip
                label={t("customer_layout.summary_labels.phone")}
                value={customer.data.telephoneNumber ?? ""}
              />
              <LabelledChip
                label={t("customer_layout.summary_labels.email")}
                value={customer.data.email ?? ""}
              />
            </>
          ) : undefined
        }
        onResize={onHeaderResize}
      />
      {headerRect && (
        <SidebarLayout
          sidebar={
            <Box
              marginTop={`${headerRect.top + headerRect.height}px`}
              paddingY={2}
            >
              <SidebarNavigation
                heading={t("customer_layout.summary_labels.customer")}
                menu={navigationMenu}
              />
            </Box>
          }
        >
          <CustomerProvider customerId={customerId}>
            <Outlet />
          </CustomerProvider>
        </SidebarLayout>
      )}
    </Box>
  );
}

export default withTranslationLoader(["clients", "customers"])(CustomerLayout);
