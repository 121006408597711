import SettingsLayout from "apps/servicing/layouts/SettingsLayout";
import AgreementLayout from "apps/servicing/modules/agreements/layouts/AgreementLayout";
import {
  AddAgreementFinancialPostingView,
  AddTransactionView,
  ActivityView as AgreementActivityView,
  AssetsView as AgreementAssetsView,
  DocumentsView as AgreementDocumentsView,
  AgreementFinancialPostingsView,
  AgreementInsuranceView,
  MemosView as AgreementMemosView,
  OverviewView as AgreementOverviewView,
  AgreementThirdPartiesView,
  AgreementsView,
  ApplySettlementQuoteView,
  PaymentInstructionView,
  PaymentInstructionsView,
  ScheduleView,
  SecondaryRentalsView,
  SettlementQuoteDetailsView,
  SettlementQuotesView,
  TerminationAndWriteOffView,
  TransactionsView,
} from "apps/servicing/modules/agreements/views";
import CustomerLayout from "apps/servicing/modules/customers/layouts/CustomerLayout";
import {
  AddContactView,
  ActivityView as CustomerActivityView,
  AgreementsView as CustomerAgreementsView,
  AssetsView as CustomerAssetsView,
  BankAccountView as CustomerBankAccountView,
  BankAccountsView as CustomerBankAccountsView,
  ContactsView as CustomerContactsView,
  DocumentsView as CustomerDocumentsView,
  MemosView as CustomerMemosView,
  OverviewView as CustomerOverviewView,
  CustomersView,
  EditContactView,
  EditCustomerView,
} from "apps/servicing/modules/customers/views";
import {
  AddChargeView,
  AddCompaniesView,
  AddPortfolioBankAccountView,
  ChargeDetailsView,
  ChargesView,
  CompaniesView,
  CompanyDetailsView,
  PortfolioBankAccountDetailsView,
  PortfolioBankAccountsView,
  PortfolioDetailsView,
  ThirdPartiesView,
  UpdateCompanyView,
  UpdatePortfolioBankAccountView,
  VatCodeDetailsView,
  VatCodesView,
} from "apps/servicing/modules/settings/views";
import { AssetDialogView } from "apps/servicing/views";
import { TasksLayout } from "common/layouts";
import { AgreementProvider } from "common/providers";
import { withAppAccess } from "common/security/withAppAccess";
import PageNotFoundView from "common/views/PageNotFoundView";
import { compose } from "lodash/fp";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteAdapter } from "sdk/router/RouteAdapter";
import { withTranslationLoader } from "sdk/views";
import { useShellLayout } from "shell";
import Navigation from "./navigation";

export const ServicingSettingsRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<PageNotFoundView />} />
      <Route path="companies">
        <Route index element={<CompaniesView />} />
        <Route path="*" element={<PageNotFoundView />} />
        <Route path="add-company" element={<AddCompaniesView />} />
        <Route
          path=":companyId/*"
          element={
            <RouteAdapter
              params={["companyId"]}
              render={({ params: { companyId } }) => (
                <Routes>
                  <Route path="*" element={<PageNotFoundView />} />
                  <Route
                    index
                    element={<CompanyDetailsView companyId={companyId} />}
                  />
                  <Route
                    path="edit-company"
                    element={<UpdateCompanyView companyId={companyId} />}
                  />
                  <Route path="portfolios">
                    <Route path="*" element={<PageNotFoundView />} />
                    <Route
                      path=":portfolioId/*"
                      element={
                        <RouteAdapter
                          params={["portfolioId"]}
                          render={({ params: { portfolioId } }) => (
                            <PortfolioDetailsView
                              companyId={companyId}
                              portfolioId={portfolioId}
                            />
                          )}
                        />
                      }
                    />
                  </Route>
                </Routes>
              )}
            />
          }
        />
      </Route>
      <Route path="bank-accounts">
        <Route path="*" element={<PageNotFoundView />} />
        <Route index element={<PortfolioBankAccountsView />} />
        <Route
          path="add-bank-account"
          element={<AddPortfolioBankAccountView />}
        />
        <Route
          path=":portfolioBankAccountId/*"
          element={
            <RouteAdapter
              params={["portfolioBankAccountId"]}
              render={({ params: { portfolioBankAccountId } }) => (
                <Routes>
                  <Route path="*" element={<PageNotFoundView />} />
                  <Route
                    index
                    element={
                      <PortfolioBankAccountDetailsView
                        portfolioBankAccountId={portfolioBankAccountId}
                      />
                    }
                  />
                  <Route
                    path="edit"
                    element={
                      <UpdatePortfolioBankAccountView
                        portfolioBankAccountId={portfolioBankAccountId}
                      />
                    }
                  />
                </Routes>
              )}
            />
          }
        />
      </Route>
      <Route path="vat-codes">
        <Route path="*" element={<PageNotFoundView />} />
        <Route index element={<VatCodesView />} />
        <Route
          path=":vatCode"
          element={
            <RouteAdapter
              params={["vatCode"]}
              render={(route) => (
                <VatCodeDetailsView vatCode={route.params.vatCode} />
              )}
            />
          }
        />
      </Route>
      <Route path="third-parties" element={<ThirdPartiesView />} />
      <Route path="charges">
        <Route path="*" element={<PageNotFoundView />} />
        <Route index element={<ChargesView />} />
        <Route path="add-charge" element={<AddChargeView />} />
        <Route
          path=":chargeCode"
          element={
            <RouteAdapter
              params={["chargeCode"]}
              render={(route) => (
                <ChargeDetailsView chargeCode={route.params.chargeCode} />
              )}
            />
          }
        />
      </Route>
    </Routes>
  );
};

const App = () => {
  const [taskDrawerOpen, setTaskDrawerOpen] = useState(false);

  useShellLayout({
    navigation: (
      <Navigation
        taskDrawer={{
          isOpen: taskDrawerOpen,
          toggle: () => setTaskDrawerOpen((current) => !current),
        }}
      />
    ),
  });

  return (
    <Routes>
      <Route path="*" element={<TasksLayout open={taskDrawerOpen} />}>
        <Route path="*" element={<PageNotFoundView />} />
        <Route index element={<Navigate to="customers" replace />} />
        <Route path="agreements/*">
          <Route path="*" element={<PageNotFoundView />} />
          <Route index element={<AgreementsView />} />
          <Route
            path=":agreementId/*"
            element={
              <RouteAdapter
                params={["agreementId"]}
                render={(route) => {
                  const agreementId = Number(route.params.agreementId);
                  return (
                    <AgreementProvider agreementId={agreementId}>
                      <AgreementLayout />
                    </AgreementProvider>
                  );
                }}
              />
            }
          >
            <Route path="*" element={<PageNotFoundView />} />
            <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<AgreementOverviewView />} />
            <Route path="repayment-schedule" element={<ScheduleView />} />
            <Route path="transactions" element={<TransactionsView />} />
            <Route
              path="transactions/add-transaction"
              element={<AddTransactionView />}
            />

            <Route
              path="assets"
              element={
                <RouteAdapter<"selectedAsset">
                  render={(route) => (
                    <>
                      <AgreementAssetsView />

                      {route.searchParams.selectedAsset && (
                        <AssetDialogView
                          assetId={Number(route.searchParams.selectedAsset)}
                          open={Boolean(route.searchParams.selectedAsset)}
                        />
                      )}
                    </>
                  )}
                />
              }
            />
            <Route
              path="secondary-rentals"
              element={<SecondaryRentalsView />}
            />
            <Route path="activity" element={<AgreementActivityView />} />
            <Route path="memos" element={<AgreementMemosView />} />
            <Route path="documents" element={<AgreementDocumentsView />} />
            <Route
              path="payment-instructions"
              element={<PaymentInstructionsView />}
            />
            <Route
              path="payment-instructions/:paymentInstructionId"
              element={
                <RouteAdapter
                  params={["paymentInstructionId"]}
                  render={(route) => (
                    <PaymentInstructionView
                      paymentInstructionId={Number(
                        route.params.paymentInstructionId,
                      )}
                    />
                  )}
                />
              }
            />
            <Route
              path="third-parties"
              element={<AgreementThirdPartiesView />}
            />
            <Route path="insurance" element={<AgreementInsuranceView />} />
            <Route
              path="settlement-quotes"
              element={<SettlementQuotesView />}
            />
            <Route
              path="settlement-quotes/apply-settlement-quote"
              element={<ApplySettlementQuoteView />}
            />
            <Route
              path="settlement-quotes/:settlementQuoteId"
              element={
                <RouteAdapter
                  params={["settlementQuoteId"]}
                  render={(route) => (
                    <SettlementQuoteDetailsView
                      settlementQuoteId={Number(route.params.settlementQuoteId)}
                    />
                  )}
                />
              }
            />
            <Route
              path="financial-postings"
              element={<AgreementFinancialPostingsView />}
            />
            <Route
              path="financial-postings/add-financial-posting"
              element={<AddAgreementFinancialPostingView />}
            />
            <Route
              path="terminations"
              element={<TerminationAndWriteOffView />}
            />
          </Route>
        </Route>
        <Route path="customers/*">
          <Route path="*" element={<PageNotFoundView />} />
          <Route index element={<CustomersView />} />
          <Route
            path=":customerId/*"
            element={
              <RouteAdapter
                params={["customerId"]}
                render={(route) => {
                  const customerId = Number(route.params.customerId);
                  return <CustomerLayout customerId={customerId} />;
                }}
              />
            }
          >
            <Route path="*" element={<PageNotFoundView />} />
            <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<CustomerOverviewView />} />
            <Route path="activity" element={<CustomerActivityView />} />
            <Route
              path="assets"
              element={
                <RouteAdapter<"selectedAsset">
                  render={(route) => (
                    <>
                      <CustomerAssetsView />

                      {route.searchParams.selectedAsset && (
                        <AssetDialogView
                          assetId={Number(route.searchParams.selectedAsset)}
                          open={Boolean(route.searchParams.selectedAsset)}
                        />
                      )}
                    </>
                  )}
                />
              }
            />
            <Route path="memos" element={<CustomerMemosView />} />
            <Route path="agreements" element={<CustomerAgreementsView />} />
            <Route path="contacts" element={<CustomerContactsView />} />
            <Route path="contacts/add-contact" element={<AddContactView />} />
            <Route
              path="contacts/edit-customer"
              element={<EditCustomerView />}
            />
            <Route
              path="contacts/:contactId/edit-contact/"
              element={<EditContactView />}
            />
            <Route
              path="bank-accounts"
              element={<CustomerBankAccountsView />}
            />
            <Route
              path="bank-accounts/:bankAccountId"
              element={
                <RouteAdapter
                  render={(route) => (
                    <CustomerBankAccountView
                      bankAccountId={Number(route.params.bankAccountId)}
                    />
                  )}
                />
              }
            />
            <Route path="documents" element={<CustomerDocumentsView />} />
          </Route>
        </Route>

        <Route path="settings" element={<SettingsLayout />}>
          <Route index element={<Navigate to="companies" replace />} />
          <Route index path="*" element={<ServicingSettingsRoutes />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default compose(
  withTranslationLoader([
    "agreements",
    "customers",
    "third-parties",
    "clients",
    "documents",
    "servicing",
  ]),
  withAppAccess("servicing"),
)(App);
