import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { CustomersApi } from "generated/servicing-v2/apis";
import { CustomerModel } from "generated/servicing-v2/models";
import { useState } from "react";
import errorHandler from "support/error-handler";
import { useSWRConfig } from "swr";
import { keys } from ".";
import { keys as contactKeys } from "./contacts";

interface UpdateCustomerSubmitReturn {
  command: (customer: CustomerModel) => Promise<void>;
  isLoading: boolean;
  error: Error | undefined;
}

export const useUpdateCustomer = (
  customerId: string,
  options: { onSuccess?: () => void } = {},
): UpdateCustomerSubmitReturn => {
  const api = useApi(CustomersApi, "v2");
  const swr = useSWRConfig();
  const error = useResponseError();
  const [isLoading, setIsLoading] = useState(false);

  const command = async (customer: CustomerModel): Promise<void> => {
    try {
      setIsLoading(true);
      await api.updateCustomer({
        id: customerId,
        customerModel: customer,
      });
      options.onSuccess?.();
      swr.mutate(keys.detail(customerId));
      swr.mutate(contactKeys.list(customerId));
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    command,
    isLoading,
    error: error.message ? new Error(error.message) : undefined,
  };
};
