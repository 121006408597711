import { PagedQuery, useQueryPaged } from "common/hooks";
import { useApi } from "common/providers";
import { ChargeTypeQueryApi } from "generated/core/apis";
import { ChargeTypeListItemModel } from "generated/core/models";
import { keys } from ".";

export const useListChargeTypes: PagedQuery<ChargeTypeListItemModel> = ({
  pageSize,
} = {}) => {
  const api = useApi(ChargeTypeQueryApi);

  return useQueryPaged(
    keys.all(),
    async (_key, paging) =>
      api.listChargeTypesRaw({
        limit: paging.pageSize,
        offset: paging.offset,
      }),
    { limit: pageSize },
  );
};
