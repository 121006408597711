import useQuery, { UseQueryReturn } from "common/hooks/useQuery";
import { useApi } from "common/providers/ApiProvider";
import { ClientQueryApi } from "generated/core/apis/ClientQueryApi";
import { BankAccountDetailsModel } from "generated/core/models/BankAccountDetailsModel";
import { ErrorLike } from "support/error-handler";
import * as keys from "./keys";

type UseGetBankAccountDetails = (
  clientId: number,
  bankAccountId: number,
) => UseQueryReturn<BankAccountDetailsModel, ErrorLike>;

export const useGetBankAccountDetails: UseGetBankAccountDetails = (
  clientId,
  bankAccountId,
) => {
  const api = useApi(ClientQueryApi);

  return useQuery(keys.detail(clientId, bankAccountId), () =>
    api.getBankAccountDetails({ clientId, bankAccountId }),
  );
};
