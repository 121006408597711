import { Close, Warning } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { BankAccountFieldSet } from "apps/servicing/modules/customers/components";
import { useBankAccountResolver } from "apps/servicing/modules/customers/hooks";
import { BankAccountFormValues } from "apps/servicing/modules/customers/hooks/useBankAccountResolver";
import { ControlledTextField } from "common/components";
import { useResponseError } from "common/hooks";
import { useApi } from "common/providers";
import { ClientCommandApi, ClientQueryApi } from "generated/core/apis";
import { CreateBankAccountModel } from "generated/core/models";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import errorHandler from "support/error-handler";
import useSWR, { useSWRConfig } from "swr";
import { v4 as uuidv4 } from "uuid";

interface AddBankAccountProps {
  clientId: number;
  onSubmit(): void;
  onClose(): void;
  open: boolean;
}

export default function AddBankAccount({
  clientId,
  onSubmit: onSubmitCallback,
  onClose,
  open,
}: AddBankAccountProps): ReactElement {
  const { t } = useTranslation(["clients", "common"]);
  const clientCommandApi = useApi(ClientCommandApi);
  const swr = useSWRConfig();
  const clientQueryApi = useApi(ClientQueryApi);
  const bankAccountsKey = ["/clients/:id/bank-accounts", clientId];
  const bankAccounts = useSWR(bankAccountsKey, () =>
    clientQueryApi.listBankAccounts({ clientId }),
  );
  const contactTypes = useSWR(["/clients/:id/contacts", clientId], () =>
    clientQueryApi.listContacts({ clientId }),
  );
  const resolver = useBankAccountResolver();
  const error = useResponseError();
  const { reset, ...form } = useForm<BankAccountFormValues>({
    resolver,
  });

  useEffect(() => {
    reset({ typeId: "" });
  }, [open, reset]);

  const onSubmit = async (data: BankAccountFormValues): Promise<void> => {
    try {
      const createBankAccountModel: CreateBankAccountModel = {
        bankName: data.bankName,
        accountHolderName: data.accountHolderName,
        accountNumber: data.accountNumber,
        sortCode: data.sortCode,
        iban: data.iban === "" ? undefined : data.iban,
        bic: data.bic === "" ? undefined : data.bic,
        address: {
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          addressLine3: data.addressLine3,
          addressLine4: data.addressLine4,
          postcode: data.postcode,
        },
      };
      await clientCommandApi.createBankAccount({
        clientId: Number(data.typeId),
        createBankAccountModel,
      });
      swr.mutate(bankAccountsKey);
      onSubmitCallback();
    } catch (errorResponse) {
      error.setError((await errorHandler(errorResponse)).code);
    }
  };

  const filterContactTypes = contactTypes.data?.filter(
    (contactType) =>
      !bankAccounts.data
        ?.map((bankAccount) => bankAccount.contactType)
        .includes(contactType.type),
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      aria-labelledby="add-bank-account-title"
      open={open}
    >
      <form
        aria-label={t("add_bank_account.title_label")}
        noValidate
        method="POST"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <DialogTitle id="add-bank-account-title">
          <Box alignItems="center" display="flex">
            <Box flexGrow={1}>{t("add_bank_account.title_label")}</Box>
            <Box>
              <IconButton
                aria-label={t("common:close")}
                size="small"
                onClick={onClose}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          {filterContactTypes?.length === 0 && (
            <Alert severity="warning" icon={<Warning />}>
              <Box component="header" display="flex" alignItems="center">
                <Typography variant="body2">
                  {t("add_bank_account.no_type_available_error_message")}
                </Typography>
              </Box>
            </Alert>
          )}

          <Box marginBottom={1} />
          <ControlledTextField
            select
            id="typeId"
            name="typeId"
            label={t("add_bank_account.type_label")}
            helperText={form.formState.errors?.typeId?.message}
            error={form.formState.errors?.typeId?.message}
            SelectProps={{ displayEmpty: true }}
            control={form.control}
            required
          >
            <MenuItem value="">
              {filterContactTypes?.length === 0 ? (
                <i>No Available Contacts</i>
              ) : (
                <i>Please Select</i>
              )}
            </MenuItem>

            {filterContactTypes?.map((contactType) => (
              <MenuItem key={uuidv4()} value={contactType.id}>
                {contactType.type}
              </MenuItem>
            ))}
          </ControlledTextField>

          <BankAccountFieldSet
            errors={form.formState.errors}
            register={form.register}
          />

          {error.message && (
            <Typography color="error" data-testid="form.error">
              {error.message}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={form.formState.isSubmitting}
          >
            {t("add_bank_account.add_account_button")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
