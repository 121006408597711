import { DateField, Form } from "@ldms/mui-sdk/forms";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { useUpdateCustomer } from "api/customers";
import { useGetCustomerV2 } from "api/customers/contacts/getCustomer";
import { DigitalCorrespondencePreferences } from "apps/servicing/modules/customers/containers/EditContactContainer/EditContactContainer";
import { ControlledTextField, Loader, QueryError } from "common/components";
import { useYupResolver } from "common/hooks";
import { CompanySizeModel } from "generated/core/models";
import { Resolver, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface UpdateCustomerFieldValues {
  companyType: string;
  companyName?: string;
  companyRegistrationNumber?: string;
  taxRegistrationNumber?: string;
  title?: string;
  firstName?: string;
  secondName?: string;
  lastName?: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3?: string;
  addressLine4?: string;
  postcode: string;
  dateOfBirth?: Date;
  companySize?: string;
  nationalIdentificationNumber?: string;
  sectorOfEconomicActivityCode?: string;
  companySectorCode?: string;
  receiveSystemGeneratedCorrespondence: boolean;
}

const EditCustomerFormFields = ({
  form,
  isIndividual,
}: {
  form: UseFormReturn<UpdateCustomerFieldValues>;
  isIndividual: boolean;
}) => {
  const { t } = useTranslation("clients");

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sm={6}>
            <TextField
              {...form.register("companyType")}
              disabled
              label={t("update_customer.company_type_label")}
            />
          </Grid>

          {!isIndividual ? (
            <>
              <Grid item sm={6}>
                <TextField
                  label={t("update_customer.company_name_label")}
                  {...form.register("companyName")}
                  error={Boolean(form.formState.errors?.companyName?.message)}
                  helperText={form.formState.errors?.companyName?.message}
                  required
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  error={Boolean(
                    form.formState.errors?.companyRegistrationNumber?.message,
                  )}
                  helperText={
                    form.formState.errors?.companyRegistrationNumber?.message
                  }
                  label={t("update_customer.company_registration_number_label")}
                  {...form.register("companyRegistrationNumber")}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(
                    form.formState.errors?.taxRegistrationNumber?.message,
                  )}
                  helperText={
                    form.formState.errors?.taxRegistrationNumber?.message
                  }
                  label={t("update_customer.vat_registration_number_label")}
                  {...form.register("taxRegistrationNumber")}
                />
              </Grid>
              <Grid item sm={6}>
                <ControlledTextField
                  SelectProps={{ displayEmpty: true }}
                  control={form.control}
                  id="companySize"
                  name="companySize"
                  label={t("update_customer.company_size_label")}
                  error={form.formState.errors?.companySize?.message}
                  helperText={form.formState.errors?.companySize?.message}
                  select
                  defaultValue=""
                >
                  <MenuItem value="">{t("common:please_select")}</MenuItem>
                  {companySize.map((type) => (
                    <MenuItem value={String(type)} key={String(type)}>
                      {type}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(
                    form.formState.errors?.sectorOfEconomicActivityCode
                      ?.message,
                  )}
                  helperText={
                    form.formState.errors?.sectorOfEconomicActivityCode?.message
                  }
                  label={t("update_customer.sector_of_economic_activity_label")}
                  {...form.register("sectorOfEconomicActivityCode")}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(
                    form.formState.errors?.companySectorCode?.message,
                  )}
                  helperText={form.formState.errors?.companySectorCode?.message}
                  label={t("update_customer.company_sector_label")}
                  {...form.register("companySectorCode")}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(form.formState.errors?.title?.message)}
                  helperText={form.formState.errors?.title?.message}
                  label={t("update_customer.title_label")}
                  {...form.register("title")}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(form.formState.errors?.firstName?.message)}
                  helperText={form.formState.errors?.firstName?.message}
                  label={t("update_customer.first_name_label")}
                  {...form.register("firstName")}
                  required
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(form.formState.errors?.secondName?.message)}
                  helperText={form.formState.errors?.secondName?.message}
                  label={t("update_customer.second_name_label")}
                  {...form.register("secondName")}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(form.formState.errors?.lastName?.message)}
                  helperText={form.formState.errors?.lastName?.message}
                  label={t("update_customer.last_name_label")}
                  required
                  {...form.register("lastName")}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(form.formState.errors?.companyName?.message)}
                  helperText={form.formState.errors?.companyName?.message}
                  label={t("update_customer.trading_name_label")}
                  {...form.register("companyName")}
                />
              </Grid>
              <Grid item sm={6}>
                <DateField
                  error={Boolean(form.formState.errors?.dateOfBirth?.message)}
                  helperText={form.formState.errors?.dateOfBirth?.message}
                  control={form.control}
                  name="dateOfBirth"
                  label={t("update_customer.date_of_birth_label")}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  error={Boolean(
                    form.formState.errors?.nationalIdentificationNumber
                      ?.message,
                  )}
                  helperText={
                    form.formState.errors?.nationalIdentificationNumber?.message
                  }
                  {...form.register("nationalIdentificationNumber")}
                  label={t(
                    "update_customer.national_identification_number_label",
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>
      <Grid item>
        <Grid container spacing={2} rowSpacing={0.5}>
          <Grid item sm={6}>
            <TextField
              error={Boolean(form.formState.errors?.addressLine1?.message)}
              helperText={form.formState.errors?.addressLine1?.message}
              {...form.register("addressLine1")}
              label={t("update_customer.address_line_1_label")}
              required
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              error={Boolean(form.formState.errors?.addressLine2?.message)}
              helperText={form.formState.errors?.addressLine2?.message}
              {...form.register("addressLine2")}
              label={t("update_customer.address_line_2_label")}
              required
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              error={Boolean(form.formState.errors?.addressLine3?.message)}
              helperText={form.formState.errors?.addressLine3?.message}
              {...form.register("addressLine3")}
              label={t("update_customer.address_line_3_label")}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              error={Boolean(form.formState.errors?.addressLine4?.message)}
              helperText={form.formState.errors?.addressLine4?.message}
              {...form.register("addressLine4")}
              label={t("update_customer.address_line_4_label")}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              error={Boolean(form.formState.errors?.postcode?.message)}
              helperText={form.formState.errors?.postcode?.message}
              {...form.register("postcode")}
              required
              label={t("update_customer.postcode_label")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Divider flexItem variant="fullWidth" />
      </Grid>
      <Grid item>
        <DigitalCorrespondencePreferences<UpdateCustomerFieldValues>
          isInvoicing={false}
          control={form.control}
        />
      </Grid>
    </Grid>
  );
};

const companySize = Object.values(CompanySizeModel);

const useUpdateClientResolver = (
  isIndividual: boolean,
  labels: {
    postcode: string;
    addressLine4: string;
    addressLine3: string;
    addressLine2: string;
    addressLine1: string;
    title: string;
    companyName: string;
    lastName: string;
    secondName: string;
    firstName: string;
    dateOfBirth: string;
    companyRegistrationNumber: string;
    companySectorCode: string;
    companySize: string;
    companyType: string;
    nationalIdentificationNumber: string;
    taxRegistrationNumber: string;
    sectorOfEconomicActivityCode: string;
    tradingName: string;
  },
): Resolver<UpdateCustomerFieldValues> => {
  const { t } = useTranslation("clients");

  return useYupResolver<UpdateCustomerFieldValues>((yup) =>
    yup
      .object()
      .shape(
        !isIndividual
          ? {
              taxRegistrationNumber: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema.maxCharacters(30, t(labels.taxRegistrationNumber)),
                ),
              companyName: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema
                    .isRequired(t(labels.companyName))
                    .maxCharacters(50, t(labels.companyName)),
                ),
              companyRegistrationNumber: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema.maxCharacters(30, t(labels.companyRegistrationNumber)),
                ),
              companySectorCode: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema.maxCharacters(2, t(labels.companySectorCode)),
                ),
              sectorOfEconomicActivityCode: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema.maxCharacters(
                    6,
                    t(labels.sectorOfEconomicActivityCode),
                  ),
                ),
            }
          : {
              title: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema
                    .isRequired(t(labels.title))
                    .maxCharacters(50, t(labels.title)),
                ),
              lastName: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema
                    .isRequired(t(labels.lastName))
                    .maxCharacters(50, t(labels.lastName)),
                ),
              secondName: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema.maxCharacters(50, t(labels.secondName)),
                ),
              firstName: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema
                    .isRequired(t(labels.firstName))
                    .maxCharacters(50, t(labels.firstName)),
                ),
              dateOfBirth: yup.date().when("companyType", (_, schema) =>
                schema
                  .localDate()
                  .nullable()
                  .transform((v: string, o: string) =>
                    o === "" ? undefined : v,
                  )
                  .isBeforeToday(t(labels.dateOfBirth)),
              ),
              companyName: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema.maxCharacters(50, t(labels.tradingName)),
                ),
              nationalIdentificationNumber: yup
                .string()
                .when("companyType", (_, schema) =>
                  schema.maxCharacters(
                    20,
                    t(labels.nationalIdentificationNumber),
                  ),
                ),
            },
      )
      .shape({
        postcode: yup
          .string()
          .isRequired(t(labels.postcode))
          .maxCharacters(10, t(labels.postcode)),
        addressLine4: yup.string().maxCharacters(50, t(labels.addressLine4)),
        addressLine3: yup.string().maxCharacters(50, t(labels.addressLine3)),
        addressLine2: yup
          .string()
          .isRequired(t(labels.addressLine2))
          .maxCharacters(50, t(labels.addressLine2)),
        addressLine1: yup
          .string()
          .isRequired(t(labels.addressLine1))
          .maxCharacters(50, t(labels.addressLine1)),
      }),
  );
};

const EditCustomerContainer = ({
  customerSystemId,
}: {
  customerSystemId: string;
}) => {
  const { t } = useTranslation("clients");
  const updateCustomer = useUpdateCustomer(customerSystemId, {
    onSuccess: () => navigate("../contacts"),
  });
  const navigate = useNavigate();
  const mainContact = useGetCustomerV2(customerSystemId);

  const isIndividual = !!mainContact.data?.isIndividual;

  const onSubmit = async (data: UpdateCustomerFieldValues) => {
    await updateCustomer.command({
      ...data,
      companySize: (data.companySize as CompanySizeModel) ?? undefined,
      dateOfBirth: data.dateOfBirth ?? undefined,
    });
  };

  const defaultValues = {
    addressLine1: mainContact.data?.addressLine1,
    addressLine2: mainContact.data?.addressLine2,
    addressLine3: mainContact.data?.addressLine3,
    addressLine4: mainContact.data?.addressLine4,
    postcode: mainContact.data?.postcode,
    title: mainContact.data?.title,
    firstName: mainContact.data?.firstName,
    lastName: mainContact.data?.lastName,
    secondName: mainContact.data?.secondName,
    companyName: mainContact.data?.companyName,
    tradingName: mainContact.data?.tradingName,
    companyType: mainContact.data?.customerType,
    companyRegistrationNumber: mainContact.data?.companyRegistrationNumber,
    taxRegistrationNumber: mainContact.data?.taxRegistrationNumber,
    dateOfBirth: mainContact.data?.dateOfBirth,
    companySize: mainContact.data?.companySize,
    nationalIdentificationNumber:
      mainContact.data?.nationalIdentificationNumber,
    sectorOfEconomicActivityCode:
      mainContact.data?.sectorOfEconomicActivityCode,
    companySectorCode: mainContact.data?.companySectorCode,
    receiveSystemGeneratedCorrespondence:
      mainContact.data?.isReceiveSystemGeneratedCorrespondence,
  };

  const resolver = useUpdateClientResolver(isIndividual, {
    addressLine1: "update_customer.address_line_1_label",
    addressLine2: "update_customer.address_line_2_label",
    addressLine3: "update_customer.address_line_3_label",
    addressLine4: "update_customer.address_line_4_label",
    postcode: "update_customer.postcode_label",
    companyName: "update_customer.company_name_label",
    companyRegistrationNumber:
      "update_customer.company_registration_number_label",
    companySectorCode: "update_customer.company_sector_label",
    companySize: "update_customer.company_size_label",
    companyType: "update_customer.company_type_label",
    dateOfBirth: "update_customer.date_of_birth_label",
    firstName: "update_customer.first_name_label",
    lastName: "update_customer.last_name_label",
    secondName: "update_customer.second_name_label",
    nationalIdentificationNumber:
      "update_customer.national_identification_number_label",
    sectorOfEconomicActivityCode:
      "update_customer.sector_of_economic_activity_label",
    title: "update_customer.title_label",
    taxRegistrationNumber: "update_customer.vat_registration_number_label",
    tradingName: "update_customer.trading_name_label",
  });

  return (
    <Loader
      ready={Boolean(mainContact.data ?? mainContact.error)}
      render={() => {
        if (!mainContact.data || mainContact.error) {
          return <QueryError onRetry={mainContact.refetch} />;
        }

        return (
          <Container maxWidth="sm">
            <Paper>
              <Box gap={2} padding={2}>
                <Form<UpdateCustomerFieldValues>
                  onSubmit={onSubmit}
                  label={t("update_customer.update_customer")}
                  defaultValues={defaultValues}
                  method="POST"
                  resolver={resolver}
                >
                  {(form) => (
                    <>
                      <EditCustomerFormFields
                        form={form}
                        isIndividual={isIndividual}
                      />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        gap={1}
                        mt={2}
                      >
                        <Button
                          color="primary"
                          onClick={() => navigate("../contacts")}
                        >
                          {t("common:cancel")}
                        </Button>
                        <Button
                          disabled={form.formState.isSubmitting}
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          {t("update_customer.update_customer_button")}
                        </Button>
                      </Box>
                    </>
                  )}
                </Form>
              </Box>
            </Paper>
          </Container>
        );
      }}
    />
  );
};

export default EditCustomerContainer;
