import { Loader } from "@ldms/mui-sdk/templates";
import { Box } from "@mui/material";
import { useGetBankAccountDetails } from "api/customers/bank-accounts/getBankAccountDetails";
import BankAccountDetails from "apps/servicing/modules/customers/containers/BankAccountDetails";
import { useCustomer } from "apps/servicing/modules/customers/layouts/CustomerLayout";
import { QueryError } from "common/components";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { ContainedLayout } from "common/layouts";
import ViewLayout from "common/layouts/ViewLayout";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next/";
import customerDisplayName from "support/customer-display-name";

interface BankAccountProps {
  bankAccountId: number;
}

export default function BankAccountView({
  bankAccountId,
}: BankAccountProps): ReactElement {
  const { t } = useTranslation("customers");
  const customer = useCustomer();
  const customerId = customer.data.id;
  const appConfig = useAppConfiguration();
  const bankAccount = useGetBankAccountDetails(customerId, bankAccountId);

  const renderBankAccount = () => {
    return (
      <BankAccountDetails
        bankAccountId={bankAccountId}
        clientId={customerId}
        onCancelMandates={bankAccount.refetch}
      />
    );
  };

  if (bankAccount.error) {
    return (
      <Box paddingY={2}>
        <QueryError onRetry={bankAccount.refetch} />
      </Box>
    );
  }

  return (
    <ViewLayout
      breadcrumbs={[
        {
          href: `${appConfig.appRoutes.servicing}/customers/${customerId}/bank-accounts`,
          label: t("breadcrumbs.bank_accounts_link"),
        },
      ]}
      title={
        bankAccount.data ? (
          `${bankAccount.data.contactType} - ${customerDisplayName(
            customerId,
            undefined,
            {
              title: bankAccount.data.clientTitle,
              firstName: bankAccount.data.clientFirstName,
              secondName: bankAccount.data.clientSecondName,
              lastName: bankAccount.data.clientLastName,
            },
          )}`
        ) : (
          <>&nbsp;</>
        )
      }
    >
      <ContainedLayout>
        <Loader ready={Boolean(bankAccount.data)} render={renderBankAccount} />
      </ContainedLayout>
    </ViewLayout>
  );
}
