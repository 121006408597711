import { AccessControl } from "@ldms/mui-sdk/bootstrap";
import { IconTooltipButton } from "@ldms/mui-sdk/components";
import { useFormat } from "@ldms/mui-sdk/formatting";
import { Loader } from "@ldms/mui-sdk/templates";
import { Email } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import SendEmailContainer from "apps/servicing/modules/agreements/containers/SendEmailContainer";
import UpdateAgreementStatusMenuContainer from "apps/servicing/modules/agreements/containers/UpdateAgreementStatusMenuContainer";
import { AppError } from "common/components";
import LabelledChip from "common/components/LabelledChip";
import ModuleHeader from "common/components/ModuleHeader";
import SidebarNavigation from "common/containers/SidebarNavigation";
import useAppConfiguration from "common/hooks/useAppConfiguration";
import { SidebarLayout } from "common/layouts";
import { useAgreement } from "common/providers";
import { ProductTypeModel } from "generated/onboarding/models";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

export default function AgreementLayout() {
  const appConfig = useAppConfiguration();
  const { ready, t } = useTranslation("agreements");
  const agreement = useAgreement();
  const { formatAmount } = useFormat();
  const [headerRect, setHeaderRect] = useState<DOMRectReadOnly>();

  const [isSendDialogOpen, setSendDialogOpen] = useState(false);
  const onSend = (): void => {
    setSendDialogOpen(true);
  };
  const onClose = () => {
    setSendDialogOpen(false);
  };

  // This function is a callback which is triggered by a ResizeObserver which
  // means it can only be tested in a real browser environment
  const onHeaderResize = (rect: DOMRectReadOnly) => {
    setHeaderRect(rect);
  };

  const makeItem = (label: string, to: string, display = true) => {
    return { label: t(label), to, display };
  };

  const errorCodesTranslations = new Map(
    ready
      ? [
          [
            "404",
            {
              message: t("error.agreement_404_message"),
              title: t("error.agreement_404"),
            },
          ],
          [
            "400",
            {
              message: t("error.agreement_404_message"),
              title: t("error.agreement_404"),
            },
          ],
        ]
      : undefined,
  );

  const errorCodeTranslation = errorCodesTranslations?.get(
    String(agreement.error?.status),
  );

  const navigationMenu = [
    makeItem("navigation.overview_link", "./overview"),
    makeItem("navigation.repayment_schedule_link", "./repayment-schedule"),
    makeItem("navigation.transactions_link", "./transactions"),
    makeItem("navigation.assets_link", "./assets"),
    makeItem("navigation.secondary_rentals_link", "./secondary-rentals"),
    makeItem("navigation.activity_link", "./activity"),
    makeItem("navigation.memos_link", "./memos"),
    makeItem(
      "navigation.payment_instructions_link",
      "./payment-instructions",
      agreement.isModifiable,
    ),
    makeItem("navigation.documents_link", "./documents"),
    makeItem(
      "navigation.third_parties_link",
      "./third-parties",
      agreement.isModifiable,
    ),
    makeItem("navigation.financial_postings_link", "./financial-postings"),
    makeItem(
      "navigation.settlement_quotes_link",
      "./settlement-quotes",
      agreement.data?.productName !== ProductTypeModel.FixedRateOperatingLease,
    ),
    makeItem(
      "navigation.insurance_link",
      "./insurance",
      agreement.isModifiable &&
        agreement.data?.productName !== ProductTypeModel.FixedRateLoan,
    ),
    makeItem(
      "navigation.termination_and_write_off_link",
      "./terminations",
      agreement.data?.statusCode === "TERMINATION",
    ),
  ];

  const renderAgreement = (): ReactElement => {
    if (agreement.error) {
      return (
        <AppError
          message={errorCodeTranslation?.message}
          title={errorCodeTranslation?.title}
        />
      );
    }

    const emailButton = "email.email_button";

    return (
      <>
        <Box height="100%" display="grid" gridTemplateRows="max-content 1fr">
          <ModuleHeader
            breadcrumbs={[
              agreement.data
                ? {
                    href: `${appConfig.appRoutes.servicing}/customers/${agreement.data.customerId}`,
                    label: agreement.data.customerName,
                  }
                : { label: "..." },
            ]}
            title={agreement.data?.agreementNumber ?? "..."}
            labelledChips={
              agreement.data ? (
                <>
                  <LabelledChip
                    label={t("agreement_layout.summary_labels.current_balance")}
                    value={formatAmount(agreement.data?.currentBalance)}
                  />
                  <LabelledChip
                    label={t("agreement_layout.summary_labels.capital_balance")}
                    value={formatAmount(agreement.data?.capitalBalance)}
                  />
                  <LabelledChip
                    label={t(
                      "agreement_layout.summary_labels.interest_balance",
                    )}
                    value={formatAmount(agreement.data?.interestBalance)}
                  />
                  <LabelledChip
                    label={t("agreement_layout.summary_labels.arrears")}
                    value={formatAmount(agreement.data?.arrearsBalance)}
                  />
                  <LabelledChip
                    label={t("agreement_layout.summary_labels.early_pays")}
                    value={formatAmount(agreement.data.earlyPays)}
                  />
                </>
              ) : undefined
            }
            action={
              agreement.data ? (
                <Stack spacing={2} direction="row" paddingLeft={2}>
                  <UpdateAgreementStatusMenuContainer />
                  <AccessControl
                    allowedPermissions={["servicing:agreement-email:manage"]}
                  >
                    <IconTooltipButton
                      title={t(emailButton)}
                      onClick={onSend}
                      aria-label={t(emailButton)}
                    >
                      <Email color="primary" />
                    </IconTooltipButton>
                  </AccessControl>
                </Stack>
              ) : undefined
            }
            onResize={onHeaderResize}
          />
          {headerRect && (
            <SidebarLayout
              sidebar={
                <Box
                  marginTop={`${headerRect.top + headerRect.height}px`}
                  paddingY={2}
                >
                  <SidebarNavigation
                    heading={t("sidebar.heading")}
                    menu={navigationMenu}
                  />
                </Box>
              }
            >
              <Box component="main" height="100%">
                <Outlet />
              </Box>
            </SidebarLayout>
          )}
        </Box>

        {agreement.data && (
          <SendEmailContainer open={isSendDialogOpen} onClose={onClose} />
        )}
      </>
    );
  };

  return <Loader ready={ready} render={renderAgreement} />;
}
